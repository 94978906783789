import { useEffect, useRef } from 'react';
import crypto from 'crypto-js';
import PropTypes from 'prop-types';
import { Post, apiURL, Get ,GetAll} from '../../../utils/apiCalls';
import { useNavigate } from 'react-router-dom';

const loadScript = src => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    console.log('razorpay loaded successfully');
    resolve(true);
  };
  script.onerror = () => {
    console.log('error in loading razorpay');
    resolve(false);
  };
  document.body.appendChild(script);
});

const RenderRazorpay = ({
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  email,
  phone
}) => {
  const navigate = useNavigate();
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const displayRazorpay = async (options) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.submit', (response) => {
      paymentMethod.current = response.method;
    });

    rzp1.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    rzp1.open();
  };

  const handlePayment = async (status, orderDetails = {}) => {
    console.log(status, orderDetails, "orderDetailsorderDetails")
    let payload =  status === 'success'? {status:'SUCCESS',...orderDetails}:{status:'CANCELLED',razorpay_order_id: orderId}
    const response = await Post(apiURL + '/api/v1/payments/payment_capture', payload)
    if (response.data.status) {
      if (response.data.payment_status == 'SUCCESS') {
        navigate(`/dashboard/paymentsucess/${orderId}`)
      }
}
  };


  const options = {
    key: keyId,
    amount: amount,
    currency: currency,
    order_id: orderId,
    handler: function (response) {
      console.log(response);
      handlePayment('success', response);
    },
    modal: {
      confirm_close: true, 
      ondismiss: async (reason) => {
        const {
          reason: paymentReason, field, step, code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
        if (reason === undefined) {
          console.log('cancelled');
          handlePayment('Cancelled');
        } 
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === 'timeout') {
          console.log('timedout');
          handlePayment('timedout');
        } 
        // Reason 3 - When payment gets failed.
        else {
          console.log('failed');
          handlePayment('failed', {
            paymentReason, field, step, code,
          });
        }
      },
    },

    retry: {
      enabled: false,
    },
    timeout: 900, 
    theme: {
      color: '',
    },
    prefill: {
      email: email,
      contact: phone,
    },
    theme: {
      color: '#F37254',
    },
  };
  useEffect(() => {
    console.log('Loading Razorpay...');

    console.log(orderId && amount)
    if (orderId && amount) {
      displayRazorpay(options);
    }
  }, [orderId]);

};

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  keySecret: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default RenderRazorpay;
