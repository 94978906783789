import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Card,
  Grid,
  Typography,
  Box, Avatar, IconButton
} from "@mui/material";
import { AccountBalance } from '@mui/icons-material';
const Bankdetails = () => {
  return (

        <Card sx={{boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}}>
          <CardContent>
            <Typography align="center" variant="h4">
              Bank Details
            </Typography>
            <Grid container spacing={2} sx={{ mt: 0 }}>
            <Card sx={{minWidth:300,  margin: 'auto', mt: 5, backgroundColor: 'white', boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt:2  }}>
        <Avatar sx={{ bgcolor: 'white', width: 60, height: 60,boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)" }}>
          <AccountBalance sx={{ fontSize: 40, color: 'black' }} />
        </Avatar>
      </Box>
      <CardContent > 
      <Box  sx={{ display: 'flex', mt:1 }}>
      <Typography variant="body1" color={'#0C5283'}>
        BANK NAME : 
        </Typography>
        <Typography variant="body1" color={'black'}>
         IDFC FIRST BANK
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1  }}>
      <Typography variant="body1" color={'#0C5283'}>
        ACCOOUNT NAME : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;PERFECT LAYOUT PRIVATE LIMITED
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1  }}>
      <Typography variant="body1" color={'#0C5283'}>
        ACCOUNT NUMBER : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;10154471583
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1 }}>
      <Typography variant="body1" color={'#0C5283'}>
        IFSC CODE : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;IDFB0080218
        </Typography>
      </Box>  
      </CardContent>
    </Card>
    <Card sx={{minWidth:300,  margin: 'auto', mt: 5, backgroundColor: 'white', boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt:2  }}>
        <Avatar sx={{ bgcolor: 'white', width: 60, height: 60,boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)" }}>
          <AccountBalance sx={{ fontSize: 40, color: 'black' }} />
        </Avatar>
      </Box>
      <CardContent > 
      <Box  sx={{ display: 'flex', mt:1 }}>
      <Typography variant="body1" color={'#0C5283'}>
        BANK NAME : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;AXIS BANK
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1  }}>
      <Typography variant="body1" color={'#0C5283'}>
        ACCOOUNT NAME : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;PERFECT LAYOUT PRIVATE LIMITED
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1  }}>
      <Typography variant="body1" color={'#0C5283'}>
        ACCOUNT NUMBER : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;923020028189536
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', mt:1 }}>
      <Typography variant="body1" color={'#0C5283'}>
        IFSC CODE : 
        </Typography>
        <Typography variant="body1" color={'black'}>
        &nbsp;UT1B0004272
        </Typography>
      </Box>  
      </CardContent>
    </Card>
   
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0 }}>
            <Card sx={{maxWidth:300,  margin: 'auto', mt: 5, backgroundColor: 'white', boxShadow:"0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.2)"}}>
            <img
            srcSet={`https://perfectlayoutdata.s3.ap-south-1.amazonaws.com/data/PerfectlayoutQR.jpg`}
            src={`https://perfectlayoutdata.s3.ap-south-1.amazonaws.com/data/PerfectlayoutQR.jpg`}
            alt={'QR'}
            width={300}
            loading="lazy"
          />
           <Typography variant="body2" color={'black'} sx={{padding:1}}>
        Scan this QR code using any UPI app (like PhonePe, Googlepay, Paytm, etc.)
        </Typography>
            </Card>
            </Grid>

          </CardContent>
        </Card>
  
  )
};

export default Bankdetails;
