// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const navConfigAdmin =
 [
  {
    title: 'dashboard',
    path: '/dashboard/perfectlayout',
    icon: getIcon('eva:pie-chart-2-fill'  ),
  },
  {
    title: 'Projects',
    path: '/dashboard/projectadmin',
    icon: getIcon('mdi:land-plots-marker'),
  },
  {
    title: 'My Admins',
    icon: getIcon('mdi:users-add'),
    path: '/dashboard/adminUser'
  },
  {
    title: 'All Associate',
    icon: getIcon('clarity:users-solid'),
    path: '/dashboard/adminAgentUser'
  },
  {
    title: 'Manage offers',
    icon: getIcon('clarity:users-solid'),
    path: '/dashboard/offers'
  },
  {
    title: 'Customer Offers',
    path: '/dashboard/Customeroffers',
    icon: getIcon('ic:baseline-local-offer'),
  },
  {
    title: 'Agent Offers',
    path: '/dashboard/agentoffers',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Commission (Dont use)',
    path: '/dashboard/commission',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Bank Details',
    path: '/dashboard/bankdetails',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Posters',
    path: '/dashboard/imgEdit',
    icon: getIcon('icons8:advertising'),
    children: [
      {
        title: 'Posters',
        path:'/dashboard/imgEdit',
      },
      {
        title: 'Visting Cards',
        path: '/dashboard/VisitingPoster',
      },
      {
        title: 'Daily Quotation',
        path:'/dashboard/Quotations',
      },
    ]
    },
    {
      title: 'Payments',
      path: '/dashboard/paymentdetails',
      icon: getIcon('material-symbols:payments-sharp'),
    },
  
];

export const navConfigAgent =
 [
  {
    title: 'dashboard',
    path: '/dashboard/perfectlayout',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Projects',
    path: '/dashboard/projectagent',
    icon: getIcon('mdi:land-plots-marker'),
  },
  {
    title: 'My Associate',
    icon: getIcon('clarity:users-solid'),
    path: '/dashboard/agentUser'
  },
  {
    title: 'My Wallet',
    icon: getIcon('solar:wallet-bold'),
    path: '/dashboard/wallet'
  },
  {
    title: 'Customer Offers',
    path: '/dashboard/Customeroffers',
    icon: getIcon('ic:baseline-local-offer'),
  },
  {
    title: 'Agent Offers',
    path: '/dashboard/agentoffers',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Commission (Dont use)',
    path: '/dashboard/commission',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Bank Details',
    path: '/dashboard/bankdetails',
    icon: getIcon('material-symbols:real-estate-agent-rounded'),
  },
  {
    title: 'Posters',
    path: '/dashboard/imgEdit',
    icon: getIcon('icons8:advertising'),
    children: [
      {
        title: 'Posters',
        path:'/dashboard/imgEdit',
      },
      {
        title: 'Visting Cards',
        path: '/dashboard/VisitingPoster',
      },
      {
        title: 'Daily Quotation',
        path:'/dashboard/Quotations',
      },
    ]
    },
    {
      title: 'Payments',
      path: '/dashboard/paymentdetails',
      icon: getIcon('material-symbols:payments-sharp'),
    },
    
];