import React, { useEffect, useState, useRef } from 'react';
import { Paper, Button, CardContent, CardActions,Table,TableBody, TableCell, TableContainer, Card, TextField, Box, Stack, FormControl, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, IconButton, TablePagination } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { padding } from '@mui/system';
import { GetAll, Post, apiURL } from '../../../utils/apiCalls';
import { useParams } from 'react-router-dom';
const PaymentSucess = () => {
  const [data, setData] = useState({})
  const {id} = useParams()
  async function GetList () {
    const response = await GetAll(apiURL + `/api/v1/payments/get_payment_details_by_order_id/${id}`);      
    if (response) {
      if (response.data.status) {
        setData(response.data.data)
      }
    }
};

  useEffect(()=> {
    GetList();    
  },[])
  
  return (<Card sx={{ ml: { xs: 3, sm: 3, lg: 50, md: 50, xl: 50 }, mr: { xs: 3, sm: 3, lg: 50, md: 50, xl: 50 } }} elevation={12} >
              <CardContent>
            {/* <Typography align='center' variant='h4'>PAYMENT</Typography>           */}
            <Grid container  sx={{ mt: 0 ,justifyContent:'center'}}>
                <CheckCircleIcon sx={{ width: '5.5em', height: '5.5em', color: 'green' }} />
                
            </Grid>
            <Typography align='center' variant='h6'>PAYMENT SUCCESS</Typography>  
            <TableContainer 
      component={Paper} 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        maxWidth: 600, 
        margin: 'auto', 
          mt: 2,
          boxShadow:'none'
      }}
    >
      <Table aria-label="payment details"     sx={{ borderCollapse: 'collapse' }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{pt:1,pb:1,pl:0,pr:0}}>
              <Typography variant="h12" sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: '#333' 
                }}>AMOUNT</Typography>
            </TableCell>
            <TableCell align="right" sx={{pt:1,pb:1,pl:0,pr:0}}>
              <Typography variant="h12" sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: 'grey' 

                }}>{`₹ ${data?.pay_amount}`}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{pt:1,pb:1,pl:0,pr:0}}>
              <Typography variant="h12"  sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: '#333' 
                }} >PAYMENT REF ID</Typography>
            </TableCell>
            <TableCell align="right" sx={{pt:1,pb:1,pl:0,pr:0}}>
              <Typography variant="h12" sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: 'grey' 

                }}>{data?.razorpay_payment_id}</Typography>
            </TableCell>
          </TableRow>

          <TableRow  sx={{border:0,p:0}}>
            <TableCell sx={{pt:1,pb:1,pl:0,pr:0}} >
              <Typography variant="h12"    sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: '#333' 
                }} >PAYMENT ORDER ID</Typography>
            </TableCell>
            <TableCell align="right"  sx={{pt:1,pb:1,pl:0,pr:0}}>
              <Typography variant="h12"     sx={{ 
                  fontFamily: 'Roboto, sans-serif', 
                  fontWeight: 500, 
                  fontSize: '0.9rem',
                  color: 'grey' 

                }}>{data?.razorpay_order_id}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
            {/* <Typography align='center' variant='h8'>AMOUNT : 1000</Typography>   
            <Typography align='center' variant='h8'>PAYMENT REF ID : kmdosjforntot</Typography>   
            <Typography align='center' variant='h8'>PAYMENT ORDER ID : rokeornrpe</Typography>    */}


            <Grid container spacing={2} sx={{ mt: 0 ,justifyContent:'center'}}>
            <Button variant="contained" sx={{ mt: 2, ml: 2, mr: 2 }} align="center"   onClick={() =>  window.open(data?.filepaths3orginal, '_blank') }>DOWNLOAD PAYEMNT RECIPIENT</Button>
                
            </Grid>
        </CardContent>
        </Card>)
}
export default PaymentSucess