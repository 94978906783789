import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Paper, Button, CardContent, CardActions, Card, TextField, Box, Stack, FormControl, FormControlLabel, Checkbox, FormHelperText, MenuItem, Select, InputLabel, Autocomplete, Grid, Divider, Typography, TableRow, TableCell, IconButton, TablePagination } from '@mui/material';
import { Post, apiURL, Get ,GetAll} from '../../../utils/apiCalls';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RenderRazorpay from '../agents/RenderRazorpay'

const AgentSchema = Yup.object().shape({
//    first_name: Yup.string().required('First Name is required'),
//    last_name: Yup.string().required('Last Name is required'),
//    email: Yup.string().email('Invalid Email').required('Email is required'),
//    password: Yup.string().required('M-Pin is required').matches(/^[0-9]{4}$/, 'Invalid M-Pin'),
   // .min(6, 'M-Pin must be at least 6 characters')
   //    .matches(
   //       /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]+$/,
   //       'M-Pin must contain at least one capital letter, one symbol, and one number'
   //    ),
//    phone: Yup.string().matches(/^[6-9]\d{9}$/, 'Please Enter valid Phone Number').required('Phone Number is required'),
//    aadhar_number: Yup.string().matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, 'Invalid Aadhar number').required('Aadhar Number is required'),
//    pan_number: Yup.string().matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN number').required('Pan Number is required'),
   //  aadhar_upload: Yup.string().required('Aadhar Upload is required'),
   //  pan_upload: Yup.string().required('Pan Upload is required'),
//    address: Yup.string().required('Personal Address is required'),
//    city: Yup.string().required('City is required'),
//    state: Yup.string().required('State is required'),
//    country: Yup.string().required('Country is required'),
//    pincode: Yup.string().required('Pincode is required'),
   // bank_name: Yup.string().required('Bank Name is required'),
   // account_number: Yup.string().required('Account Number is required'),
   // bank_ifsc_code: Yup.string().required('IFSC Number is required'),
   // bank_address: Yup.string().required('Bank Address is required'),
//    role: Yup.object().shape({ id: Yup.string().required('Role is required') }),
//    active: Yup.string().required('Active is required'),
});

const CheckOutDetails = () => {
   const { id } = useParams()
   const [data, setData] = useState({})
   const [displayRazorpay, setDisplayRazorpay] = useState(false);
   const [orderDetails, setOrderDetails] = useState({
      orderId: null,
      currency: null,
      amount: null,
     });
    const [account, setAccount] = useState();

   React.useMemo(() => {
        console.log(JSON.parse(sessionStorage.getItem('user_details')))
       setAccount(JSON.parse(sessionStorage.getItem('user_details')))
     }, [sessionStorage.getItem('user_details')])
    
   
   // console.log(id)
   const navigate = useNavigate();
   const [checked, setChecked] = useState(false);
   const getPlotDetails = async () => {
      const response = await GetAll(apiURL + '/api/v1/commision/agent/plot/'+id)
      if (response && response.data && response.data.status) {
         console.log(response.data)
          setData(response.data.data)
      }
  }
   useEffect(() => {
      getPlotDetails()
   }, []);
   

   const formik = useFormik({
      initialValues: {
         full_name: '',
           father_husband_name: '',
           occupation: '',
           dob: null,
           email: '',
           phone: '',
         address: '',
         nominee_full_name: '',
         relationship:'',
         age:'',
         nominee_occupation: '',
         pay_amount:''
      },
      validationSchema: AgentSchema,
      onSubmit: async (values, { setSubmitting }) => {
         const response = await Post(apiURL + '/api/v1/payments/payment_order', { ...values,...data })
         if (response && response.data && response.data.status) {
            console.log(response.data,"response.data")
            setSubmitting(false)
            setOrderDetails({
               orderId: response.data.data.order_id,
               currency: response.data.data.currency,
               amount: response.data.data.amount,
             });
             setDisplayRazorpay(true);
         }
         else {
            toast.error(response?.data.message || 'Please Try Again')
            setSubmitting(false)
         }
      }
   });

   const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
   // console.log(values)
   // console.log(errors)


   const handleChangeCheck = (e) => {
      setChecked(e.target.checked)
   }

   return (
      <div>
      <FormikProvider value={formik}>
         <Form  noValidate onSubmit={handleSubmit}>
            <Card>
               <CardContent>
                       <Typography align='center' variant='h4'>BOOKING</Typography>
                       <Typography variant='h7' sx={{color:'blue'}}>PERSONAL DETAILS</Typography>

                       <Divider />

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="FULL NAME" {...getFieldProps('full_name')} error={Boolean(touched.full_name && errors.full_name)} helperText={touched.full_name && errors.full_name} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="FATHER'S / HUSBAND'S NAME" {...getFieldProps('father_husband_name')} error={Boolean(touched.father_husband_name && errors.father_husband_name)} helperText={touched.father_husband_name && errors.father_husband_name} />
                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="OCCUPATION" {...getFieldProps('occupation')} error={Boolean(touched.occupation && errors.occupation)} helperText={touched.occupation && errors.occupation} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker
                              label="DATE OF BIRTH"
                              value={formik.values.dob}
                              onChange={(newValue) => {
                                 setFieldValue('dob', newValue);
                              }}
                              renderInput={(params) => <TextField {...params} error={Boolean(touched.dob && errors.dob)} helperText={touched.dob && errors.dob} fullWidth />}
                           />
                        </LocalizationProvider>

                     </Grid></Grid>

                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="EMAIL" {...getFieldProps('email')} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="PHONE NUMBER" {...getFieldProps('phone')} error={Boolean(touched.phone && errors.phone)} helperText={touched.phone && errors.phone} />
                           </Grid></Grid>
                           <Grid container spacing={2} sx={{ mt: 0 ,mb:2 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="ADDRESS" {...getFieldProps('address')} error={Boolean(touched.address && errors.address)} helperText={touched.address && errors.address} />
                     </Grid>
       
                       </Grid>
                       
                       <Typography variant='h7' sx={{color:'blue'}} >NOMINEE DETAILS</Typography>
                       

                  <Divider />
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="FULL NAME" {...getFieldProps('nominee_full_name')} error={Boolean(touched.nominee_full_name && errors.nominee_full_name)} helperText={touched.nominee_full_name && errors.nominee_full_name} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="RELATIONSHIP" {...getFieldProps('relationship')} error={Boolean(touched.relationship && errors.relationship)} helperText={touched.relationship && errors.relationship} />
                     </Grid></Grid>
                     <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <TextField fullWidth label="AGE" {...getFieldProps('age')} error={Boolean(touched.age && errors.age)} helperText={touched.age && errors.age} />
                     </Grid>
                     <Grid item xs={12} sm={12} lg={6} md={6} xl={6}  sx={{ mt: 0 ,mb:2 }}>
                        <TextField fullWidth label="OCCUPATION" {...getFieldProps('nominee_occupation')} error={Boolean(touched.nominee_occupation && errors.nominee_occupation)} helperText={touched.nominee_occupation && errors.nominee_occupation} />
                     </Grid></Grid>
                     <Typography variant='h7' sx={{color:'blue'}} >PLOT DETAILS</Typography>

                  <Divider />
                  
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }} > PROJECT NAME : </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{data?.projects?.name} </Typography>

                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4} sx={{ mt: 0, }}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }}   >    PLOT NO : </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{data?.plot_no} </Typography>

                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4} sx={{ mt: 0, }}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }}  >  FACE : </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{data?.facing} </Typography>

                     </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }}   >SQUARE YARDS:  </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{data?.plot_area} </Typography>

                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4} sx={{ mt: 0, }}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }}  >    SQUARE YARDS PER SQUARE : </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{data?.facing} </Typography>

                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4} sx={{ mt: 0, }}>
                        <Typography variant='h7' sx={{ color: 'black', fontWeight: 600 }}     >  TOTAL COST : </Typography>
                        <Typography variant='h7' sx={{color:'black',fontWeight:400}} >{'₹'+' '+data?.total_rate} </Typography>

                     </Grid>
                     <Grid item xs={12} sm={12} lg={4} md={4} xl={4} sx={{ mt: 0, }}>
       
                     <TextField fullWidth label="WILLING TO PAY (AMOUNT)(₹) *" {...getFieldProps('pay_amount')} error={Boolean(touched.pay_amount && errors.pay_amount)} helperText={touched.pay_amount && errors.pay_amount} />
                     </Grid>
                  </Grid>

               </CardContent>
               
               <Stack ml={'10px'} spacing={1} direction={'row'}>
               <FormControlLabel
          value="end"
          control={<Checkbox  checked={checked}
          onChange={handleChangeCheck} />}
          label=""
          labelPlacement="end"
        />
        <Typography align='center' justifyContent={'center'} display={'flex'} alignItems={'center'}>I Accept the <a href={require('../../../img/terms_and_conditions.pdf')} target="_blank" style={{cursor:'pointer', marginLeft:'5px'}}>Terms and Conditions</a></Typography>
               </Stack>
               <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button href="#/dashboard/agentUser" variant='contained' sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Back</Button>
                  <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!checked} sx={{ backgroundColor: '#3f51b5', '&:hover': { color: '#ffff', backgroundColor: '#3f51b5' } }}>Pay </LoadingButton>
               </CardActions>
            </Card>
         </Form>
         
         </FormikProvider>
         {console.log(displayRazorpay,"displayRazorpay")}
         {displayRazorpay && (
  <RenderRazorpay
    amount={orderDetails.amount}
    currency={orderDetails.currency}
    orderId={orderDetails.orderId}
    keyId={'rzp_test_R6tCwurDuz2V5F'}
               keySecret={''}
               email={account.email}
               phone={account.phone}
  />
  )}
      </div>
   )
};

export default CheckOutDetails;

const VisuallyHiddenInput = styled('input')({
   clip: 'rect(0 0 0 0)',
   clipPath: 'inset(50%)',
   height: 1,
   overflow: 'hidden',
   position: 'absolute',
   bottom: 0,
   left: 0,
   whiteSpace: 'nowrap',
   width: 1,
});