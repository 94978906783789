import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Box } from '@mui/material';
// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows?.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbarcustom.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  filterPlotNumber: PropTypes.string,
  filterCreatedDate: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterPlotNumber: PropTypes.func,
  onFilterCreatedDate: PropTypes.func,
  placeholder: PropTypes.string,
};

export default function UserListToolbarcustom({
  numSelected,
  filterName,
  filterPlotNumber,
  filterCreatedDate,
  onFilterName,
  onFilterPlotNumber,
  onFilterCreatedDate,
  placeholder
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* Search by Project Name */}
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          
          {/* Search by Plot Number */}
          <SearchStyle
            value={filterPlotNumber}
            onChange={onFilterPlotNumber}
            placeholder="Search by Plot Number"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          
          {/* Search by Created Date */}
          <SearchStyle
            value={filterCreatedDate}
            onChange={onFilterCreatedDate}
            placeholder="Search by Created Date"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
        </Box>
      )}
    </RootStyle>
  );
}
