// Import the editor styles
import '@pqina/pintura/pintura.css';
import { useRef } from 'react';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';


// Import the editor default configuration
import { getEditorDefaults } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';
import { useNavigate } from 'react-router-dom';

import { GetAll, apiURL, Put } from "../../../utils/apiCalls";


// get default properties


const downloadFile = (file) => {
  // Create a hidden link and set the URL using createObjectURL
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(file);
  link.download = file.name;

  // We need to add the link to the DOM for "click()" to work
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait a short moment before clean up
  setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
  }, 0);
};


function Imagepage() {
  const [account, setAccount] = useState({first_name: '', last_name: '', email:'', user_type:''});

  React.useMemo(() => {
    setAccount(JSON.parse(sessionStorage.getItem('user_details')))
  }, [sessionStorage.getItem('user_details')])
  
  const editorConfig =getEditorDefaults({})
  const editorRef = useRef(null);
  const [imagedata, setImagedata] = useState({})
  const { id } = useParams();

  const handleEditorUpdate = (imageState) => {
    console.log(imageState, "update");
    // logs: { cropLimitToImage:…, cropMinSize:{…} , cropMaxSize:{…}, … }
  };
  const handleEditorLoad = (imageState) => {
    // logs: { cropLimitToImage:…, cropMinSize:{…} , cropMaxSize:{…}, … }
  };

  const getposter = async (e) => {
    
    const response = await GetAll(apiURL + `/api/v1/posters/byid/${id}`);
    if (response && response.data && response.data.status) {
      
      setImagedata(response.data.data);
      console.log(response.data.data);
    }
  };
  useEffect(() => {
    getposter()
  }, []);

  const handleEditorProcess = (imageState) => {
    downloadFile(imageState.dest);
};
  return (
    <div className="App" style={{ height: '500px' }}>
      {console.log(imagedata,)}
      {(imagedata && Object.keys(imagedata).length>0)? 
        < PinturaEditor
      
                {...editorConfig}
      src={imagedata.signedUrl}
      ref={editorRef}
      onUpdate={handleEditorUpdate}
          onLoad={handleEditorLoad}
          onProcess={handleEditorProcess}

          
        ></PinturaEditor>
      :null
      }
    
        </div>
    );
}

export default Imagepage;